import * as React from "react";

// styles
// import "@shopify/polaris/dist/styles.css";
import {
  Page,
  Card,
  Button,
  SkeletonPage,
  Layout,
  SkeletonBodyText,
  Link,
  Banner,
  Tabs,
  Tag,
  Stack,
  PageActions,
  SkeletonDisplayText,
  DisplayText,
  Heading,
  IndexTable,
  TextStyle,
  DataTable,
  Caption,
  Thumbnail,
  Select,
  EmptyState,
} from "@shopify/polaris";
import {
  Redirect,
  Loading,
  Error,
  Toast,
  Button as BridgeButton,
} from "@shopify/app-bridge/actions";
import {
  useAppBridge,
  TitleBar as BridgeTitleBar,
} from "@shopify/app-bridge-react";
// import enTranslations from "@shopify/polaris/locales/en.json";

import { useCallback, useEffect, useState } from "react";
import useQueryParam from "../../lib/useQueryParam";
import { gql, useQuery, NetworkStatus } from "@apollo/client";

// import {App} from './App';

// ReactDOM.render(
//   document.getElementById('root'),

// markup
//import "@shopify/polaris/dist/styles.css";

// import "@shopify/polaris/dist/styles.css";
// import axios from "axios";
import sessionAxios from "../../lib/http";
import { potentialCustomerTags } from "../../lib/customerTags";
const GET_SHOP_NAME = gql`
  query Draft($id: ID!) {
    draftOrder(id: $id) {
      name
      id
      subtotalPrice
      totalWeight
      customer {
        displayName
        id
        tags
      }
      lineItems(first: 5) {
        edges {
          node {
            name
            sku
            originalUnitPrice
            discountedTotal
            discountedUnitPrice
            quantity
            image(maxWidth: 120) {
              transformedSrc
            }
          }
        }
      }
      appliedTag: privateMetafield(
        namespace: "budsworth"
        key: "applied_price_tag"
      ) {
        value
        updatedAt
      }
      tagUpdatedBy: privateMetafield(
        namespace: "budsworth"
        key: "applied_price_tag_updated_by"
      ) {
        value
        updatedAt
      }
    }
  }
`;

const IndexPage: React.FC = () => {
  // const location = useLocation();
  // const { id, shop } = useParams();

  const app = useAppBridge();
  const [id, setId] = useQueryParam("id", null);
  const [shop, setShop] = useQueryParam("shop", null);
  const [locale, setLocale] = useQueryParam("locale", "en-US");
  const [session, setSession] = useQueryParam("session", null);
  // const [loading, setLoading] = useState(true);
  const [installUrl, setInstallUrl] = useState<string>(null);
  const [selectedTag, setSelectedTag] = useState<string>(null);
  const [appliedTag, setAppliedTag] = useState<string>(null);
  const [lastUpdatedBy, setLastUpdatedBy] = useState<string>(null);
  const [tagValidationError, setTagValidationError] = useState<string>(null);
  const [customerDefaultTag, setCustomerDefaultTag] = useState<string>(null);
  const handleSelectedTagChange = useCallback(
    (value) => setSelectedTag(value),
    []
  );
  const { loading, error, data, refetch, networkStatus } = useQuery(
    GET_SHOP_NAME,
    {
      variables: {
        id: `gid://shopify/DraftOrder/${id}`,
      },
    }
  );
  const [applyEnabled, setApplyEnabled] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  // const [lineItems, setLineItems] = useState([]);
  const loadingStatus = Loading.create(app);
  const applyDiscountAction = () => {
    loadingStatus.dispatch(Loading.Action.START);
    setApplyEnabled(false);
    setRefreshing(true);
    makeUpdateRequest()
      .then(() => {
        updateToast.dispatch(Toast.Action.SHOW);
        // Redirect to the draft order
        // finalRedirect();
        // Reload the data
      })
      .finally(() => {
        loadingStatus.dispatch(Loading.Action.STOP);

        refetch().finally(() => {
          setApplyEnabled(true);
          setRefreshing(false);
        });
      });
  };
  const cancelAndReturnAction = () => {
    setApplyEnabled(false);
    finalRedirect();
  };
  const potentialTags = potentialCustomerTags();
  const tagOptions = [
    { label: "None", value: null },
    ...potentialTags.map((tag) => ({ label: tag, value: tag })),
  ];

  const redirect = Redirect.create(app);
  const updateToast = Toast.create(app, {
    message: "Order saved",
    duration: 5000,
  });

  const primaryAction = {
    content: "Apply",
    onAction: applyDiscountAction,
    disabled: !applyEnabled,
    loading: refreshing,
  };
  const secondaryActions = [
    { content: "Cancel", onAction: cancelAndReturnAction },
    { content: "Refresh", onAction: refetch },
  ];

  // const applyButton = BridgeButton.create(app, { label: "Apply" });
  // applyButton.subscribe("click", applyDiscountAction);
  // const cancelButton = BridgeButton.create(app, { label: "Cancel" });
  // cancelButton.subscribe("click", cancelAndReturnAction);

  // const titleBarOptions = {
  //   title: "Apply pricing",
  //   buttons: {
  //     primary: applyButton,
  //     secondary: [cancelButton],
  //   },
  // };
  // const pageTitleBar = TitleBar.create(app, titleBarOptions);

  const unsubscribe = app.error((data) => {
    // type will be the error type
    // action will contain the original action including its id
    // message will contain additional hints on how to fix the error
    const { type, action, message } = data;
    console.log({ type, action, message });
    // Handle all errors here
    switch (type) {
      case Error.Action.INVALID_PAYLOAD:
        //Do something with the error
        break;
    }
  });
  useEffect(() => {
    return unsubscribe;
  });

  // TODO: Based on loading information
  useEffect(() => {
    !loading ? setApplyEnabled(true) : null;
    // if (!id || !shop) {
    //   // setLoading(true);
    // } else {
    //   // setLoading(false);
    //   // setApplyEnabled(true);
    // }
  }, [loading]);
  useEffect(() => {
    if (data && data.draftOrder.customer.tags) {
      const defaultTag = data.draftOrder.customer.tags
        .map((t) => t.trim())
        .filter((t) => potentialTags.includes(t))
        .shift();
      setSelectedTag(defaultTag);
      setCustomerDefaultTag(defaultTag);
    }
    // console.log({ data });
    if (data && data.draftOrder.appliedTag) {
      const { value, updatedAt } = data.draftOrder.appliedTag;
      setSelectedTag(value);
      setAppliedTag(value);
    }
    // if (data && data.draftOrder.metafields) {
    //   let tmpAppliedTag = null;
    //   for (const {
    //     node: { key, value },
    //   } of data.draftOrder.metafields.edges) {
    //     console.log({ key, value });
    //     if (value && key === "applied_price_tag") {
    //       setSelectedTag(value);
    //       setAppliedTag(value);
    //     }
    //   }
    // }
  }, [data]);
  useEffect(() => {
    if (
      selectedTag &&
      customerDefaultTag &&
      selectedTag !== customerDefaultTag
    ) {
      setTagValidationError(
        `Pricing Override: Customer does not have "${selectedTag}" tag`
      );
    } else {
      setTagValidationError(null);
    }
  }, [selectedTag, customerDefaultTag]);
  useEffect(() => {
    if (error) {
      console.log({ error });
      setApplyEnabled(false);
      const { result } = error.networkError as any;
      if (result) {
        const { code, url } = result;
        if (code === "need-install" && url) {
          setInstallUrl(url);
        }
      }
    }
  }, [error]);

  // useEffect(() => {
  //   setRefreshing(networkStatus === NetworkStatus.refetch);
  // }, [networkStatus]);

  const makeUpdateRequest = (): Promise<any> => {
    const url = `/webhooks/shopify/apply-draft-pricing`;
    const getQuery = () => {
      if (typeof window !== "undefined") {
        return new URLSearchParams(window.location.search);
      }
      return new URLSearchParams();
    };
    // const params = Object.fromEntries(getQuery().entries());
    // const params = getQuery();
    const params = { id, shop, tag: selectedTag, session };
    // console.log({ params: params.toString() });
    const axios = sessionAxios(app);
    return axios({ method: "POST", url, params })
      .then((response) => {
        const { data } = response;
        // console.log({ data });
        return data;
      })
      .catch((reason) => {
        console.error(reason);
        return new Promise((resolve, reject) => {
          const toastOptions = {
            message: reason.toString(),
            duration: 5000,
            isError: true,
          };
          const toastError = Toast.create(app, toastOptions);
          // toastError.subscribe(Toast.Action.CLEAR, (data) => {
          //   // Do something with the clear action
          //   toastError.unsubscribe();
          // });
          toastError.dispatch(Toast.Action.SHOW);
          reject();
        });
      });
  };

  const finalRedirect = useCallback(() => {
    // const url = `https://${shop}/draft_orders/${id}`;
    redirect.dispatch(Redirect.Action.ADMIN_PATH, {
      path: `/draft_orders/${id}`,
    });
  }, [id]);
  const installRedirect = useCallback(() => {
    // const url = `https://${shop}/draft_orders/${id}`;
    const path = installUrl.replace(`https://${shop}/admin`, "");
    redirect.dispatch(Redirect.Action.ADMIN_PATH, {
      path,
    });
  }, [shop, installUrl]);
  if (installUrl) {
    return (
      <Page>
        <BridgeTitleBar title="Install" />
        <Card sectioned>
          <EmptyState
            heading="Click the button"
            // action={{ content: "Install", url: installUrl }}
            action={{
              content: "Install",
              onAction: installRedirect,
            }}
            image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
            fullWidth
          >
            <p>Install this thing to make it work...</p>
          </EmptyState>
        </Card>
      </Page>
    );
  }
  return (
    <>
      {/* <pre>{JSON.stringify({ networkStatus })}</pre> */}
      {/* <Button onClick={() => refetch()}>Refetch</Button>
      <pre>{JSON.stringify({ id, shop, loading, error })}</pre> */}
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          <Page
            title={`Apply pricing ${data ? data.draftOrder.name : null}`}
            breadcrumbs={[{ content: "Draft", onAction: finalRedirect }]}
            // primaryAction={primaryAction}
          >
            <BridgeTitleBar
              title="Apply pricing"
              primaryAction={!!data ? primaryAction : null}

              // secondaryActions={secondaryActions}
            />
            <Layout>
              {error ? (
                <Layout.Section>
                  <Banner
                    title="Unable to load draft data"
                    // action={{ content: "Review risk analysis" }}
                    status="critical"
                  >
                    <p>{error.message}</p>
                  </Banner>
                </Layout.Section>
              ) : null}

              {data ? (
                <>
                  <Layout.Section>
                    <Card title="Order details">
                      <Card.Section>
                        <Stack>
                          <Stack.Item fill>
                            <Stack vertical spacing="extraTight">
                              <Heading>Total sale</Heading>
                              {!refreshing ? (
                                <DisplayText size="large">
                                  {parseFloat(
                                    data.draftOrder.subtotalPrice
                                  ).toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })}
                                </DisplayText>
                              ) : (
                                <SkeletonDisplayText size="medium" />
                              )}
                            </Stack>
                          </Stack.Item>
                          <Stack vertical spacing="extraTight">
                            <Heading>Total weight</Heading>
                            <DisplayText size="large">
                              {parseFloat(
                                data.draftOrder.totalWeight
                              ).toLocaleString()}
                              <Caption>grams</Caption>
                            </DisplayText>
                          </Stack>
                        </Stack>
                      </Card.Section>
                      <Card.Section>
                        <Select
                          label="Selected price tag"
                          options={tagOptions}
                          onChange={handleSelectedTagChange}
                          value={selectedTag}
                          error={tagValidationError}
                        />
                      </Card.Section>
                      <Card.Section subdued title="Line items sample">
                        <DataTable
                          columnContentTypes={[
                            "text",
                            "text",
                            // "text",
                            "numeric",
                          ]}
                          headings={[
                            "Item",
                            // "SKU Number",
                            "Quantity",
                            "Total",
                          ]}
                          rows={data.draftOrder.lineItems.edges.map(
                            (
                              {
                                node: {
                                  id,
                                  image: { transformedSrc: src },
                                  name,
                                  sku,
                                  quantity,
                                  originalUnitPrice,
                                  discountedUnitPrice,
                                  discountedTotal,
                                },
                              },
                              index
                            ) => [
                              <Stack>
                                <Thumbnail
                                  source={src}
                                  alt={name}
                                  size="small"
                                />
                                <Stack vertical spacing="extraTight">
                                  <Stack.Item>{name}</Stack.Item>
                                  <Stack.Item>
                                    <Caption>SKU: {sku}</Caption>
                                  </Stack.Item>
                                  <Stack.Item>
                                    {!refreshing ? (
                                      <span>
                                        <TextStyle>
                                          {discountedUnitPrice}
                                        </TextStyle>
                                        {discountedUnitPrice !==
                                          originalUnitPrice && (
                                          <span
                                            style={{
                                              textDecoration: "line-through",
                                              paddingLeft: ".8rem",
                                            }}
                                          >
                                            <TextStyle variation="subdued">
                                              {originalUnitPrice}
                                            </TextStyle>
                                          </span>
                                        )}
                                      </span>
                                    ) : (
                                      <SkeletonBodyText lines={1} />
                                    )}
                                  </Stack.Item>
                                </Stack>
                              </Stack>,
                              quantity,
                              !refreshing ? (
                                parseFloat(discountedTotal).toLocaleString(
                                  "en-US",
                                  { style: "currency", currency: "USD" }
                                )
                              ) : (
                                <SkeletonBodyText lines={1} />
                              ),
                            ]
                          )}
                          // totals={["", "", "", 255, "$155,830.00"]}
                        />
                        {/* <IndexTable
                        resourceName={{
                          singular: "item",
                          plural: "items",
                        }}
                        selectable={false}
                        itemCount={5}
                        selectedItemsCount={0}
                        onSelectionChange={() => {}}
                        headings={[
                          { title: "Name" },
                          { title: "SKU" },
                          { title: "Quantity" },
                          { title: "Total" },
                        ]}
                      >
                        {data.draftOrder.lineItems.edges.map(
                          (
                            {
                              node: {
                                id,
                                name,
                                sku,
                                quantity,
                                discountedTotal,
                              },
                            },
                            index
                          ) => (
                            <IndexTable.Row
                              id={id}
                              key={id}
                              selected={[].includes(id)}
                              position={index}
                            >
                              <IndexTable.Cell>
                                <TextStyle variation="strong">{name}</TextStyle>
                              </IndexTable.Cell>
                              <IndexTable.Cell>{sku}</IndexTable.Cell>
                              <IndexTable.Cell>{quantity}</IndexTable.Cell>
                              <IndexTable.Cell>
                                {discountedTotal}
                              </IndexTable.Cell>
                            </IndexTable.Row>
                          )
                        )}
                      </IndexTable> */}
                        {/* <p>View a summary of your order.</p>
                      <p>{JSON.stringify({ data })}</p> */}
                      </Card.Section>
                    </Card>
                  </Layout.Section>
                  <Layout.Section secondary>
                    <Card title="Customer">
                      <Card.Section>
                        <p>
                          <Link
                            onClick={() => {
                              if (!!data.draftOrder.customer) {
                                redirect.dispatch(
                                  Redirect.Action.ADMIN_SECTION,
                                  {
                                    name: Redirect.ResourceType.Customer,
                                    resource: {
                                      id: data.draftOrder.customer.id.match(
                                        /\d+$/
                                      )[0],
                                    },
                                  }
                                );
                              }
                            }}
                          >
                            {!!data.draftOrder.customer
                              ? data.draftOrder.customer.displayName
                              : "Unknown"}
                          </Link>
                        </p>
                      </Card.Section>
                      <Card.Section title="Tags">
                        <Stack spacing="extraTight">
                          {!!data.draftOrder.customer.tags ? (
                            data.draftOrder.customer.tags.map((tag, id) => (
                              <Tag
                                key={id}
                                disabled={!potentialTags.includes(tag)}
                                onClick={() => setSelectedTag(tag)}
                              >
                                {tag}
                              </Tag>
                            ))
                          ) : (
                            <p>None</p>
                          )}
                        </Stack>
                      </Card.Section>
                    </Card>
                    {data.draftOrder.appliedTag ? (
                      <Card sectioned subdued title="Applied Discount">
                        {!refreshing ? (
                          <dl>
                            <dt>Tag</dt>
                            <dd>{data.draftOrder.appliedTag.value}</dd>
                            <dt>Updated at</dt>
                            <dd>{data.draftOrder.appliedTag.updatedAt}</dd>
                            <dt>Assigned by</dt>
                            <dd>
                              {data.draftOrder.tagUpdatedBy
                                ? data.draftOrder.tagUpdatedBy.value
                                : "Unknown"}
                            </dd>
                          </dl>
                        ) : (
                          <SkeletonBodyText lines={2} />
                        )}
                      </Card>
                    ) : null}
                  </Layout.Section>
                </>
              ) : null}
              <Layout.Section>
                <PageActions primaryAction={primaryAction} />
              </Layout.Section>
            </Layout>
            {/* <Card
            sectioned
            secondaryFooterActions={[
              {
                content: "Cancel",
                destructive: false,
                onAction: cancelAndReturnAction,
              },
            ]}
            primaryFooterAction={{
              content: "Apply",
              disabled: !applyEnabled,
              onAction: applyDiscountAction,
            }}
          >
            <p>Would you like to apply customer pricing?</p>
          </Card> */}
          </Page>
        </>
      )}
    </>
  );
};

const LoadingPage: React.FC = () => {
  return (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <Card title="Order details">
            <Card.Section>
              <SkeletonBodyText />
            </Card.Section>
            {/* <Card.Section>
              <SkeletonBodyText lines={1} />
            </Card.Section>
            <Card.Section subdued>
              <SkeletonBodyText lines={2} />
            </Card.Section> */}
            <Card.Section subdued>
              <SkeletonBodyText lines={2} />
            </Card.Section>
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card title="Customer">
            <Card.Section>
              <SkeletonDisplayText size="small" />
            </Card.Section>
            <Card.Section title="tags">
              <SkeletonBodyText lines={1} />
            </Card.Section>
          </Card>
          <Card subdued sectioned>
            <SkeletonBodyText lines={2} />
          </Card>
        </Layout.Section>
        <Layout.Section>
          <PageActions primaryAction={{ content: "Apply", disabled: true }} />
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
};

export default IndexPage;
