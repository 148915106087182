import axios from "axios";
import { getSessionToken } from "@shopify/app-bridge-utils";
// import { useAppBridge } from "@shopify/app-bridge-react";
const setup = (app: any) => {
  const instance = axios.create();
  // Intercept all requests on this Axios instance
  instance.interceptors.request.use(function (config) {
    // const app = useAppBridge()
    // const { app } = window;
    return getSessionToken(app) // requires a Shopify App Bridge instance
      .then((token) => {
        // Append your request headers with an authenticated token
        config.headers["Authorization"] = `Bearer ${token}`;
        return config;
      });
  });
  return instance;
};
// Export your Axios instance to use within your app
export default setup;
